import { getGeoJson } from './mapUtil';

const loadMapBoxLibrary = cb => {
  if (window && window.mapboxgl) {
    cb();
    return;
  }

  if (window && window._trfq) {
    window._trfq.push(['cmdLogEvent', 'load', 'ux2.contact.mapbox.load', []]);
  }

  const mapboxAPIVersion = 'v2.6.1';
  const mapboxApiUrl = `//api.mapbox.com/mapbox-gl-js/${mapboxAPIVersion}/mapbox-gl`;
  const mapScriptElement = document.createElement('script');
  mapScriptElement.setAttribute('src', `${mapboxApiUrl}.js`);
  mapScriptElement.setAttribute('type', 'text/javascript');
  mapScriptElement.setAttribute('async', 'async');
  mapScriptElement.setAttribute('crossorigin', 'anonymous');
  document.head.appendChild(mapScriptElement);
  mapScriptElement.onload = () => {
    const mapCssElement = document.createElement('link');
    mapCssElement.setAttribute('href', `${mapboxApiUrl}.css`);
    mapCssElement.setAttribute('rel', 'stylesheet');
    mapCssElement.setAttribute('async', 'async');
    document.head.appendChild(mapCssElement);
    mapCssElement.onload = cb;
  };
};

const initializeGLMap = ({
  containerId,
  interactive,
  scrollZoom,
  lat,
  lon,
  zoom,
  accessToken,
  styleUrl
}) => {
  const mapboxgl = window.mapboxgl;
  if (!mapboxgl || !mapboxgl.supported()) return null;

  const mapboxOptions = {
    container: document.getElementById(containerId),
    style: styleUrl,
    interactive,
    zoom,
    center: [lon, lat],
    minZoom: 1.5
  };

  mapboxgl.accessToken = accessToken;
  const glMap = new mapboxgl.Map(mapboxOptions);
  if (!scrollZoom) glMap.scrollZoom.disable();
  glMap.on('load', () => {
    const layerOptions = {
      id: 'markers',
      type: 'symbol',
      source: {
        type: 'geojson'
      },
      layout: {
        'icon-image': 'marker-vnext',
        'icon-size': 1
      }
    };
    layerOptions.source.data = getGeoJson({ lat, lon });
    glMap.addLayer(layerOptions);

    if (interactive) {
      const navCtl = new mapboxgl.NavigationControl();
      glMap.addControl(navCtl, 'top-right');
    }
  });

  return glMap;
};

const isSupported = () => window && window.mapboxgl && window.mapboxgl.supported();

export { loadMapBoxLibrary, initializeGLMap, isSupported };
