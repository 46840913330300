/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { UX, constants as coreConstants } from '@wsb/guac-widget-core';
import DataAid from '../constants/data-aids';
import { updateSpot, openDeviceMap } from './mapUtil';
import { loadMapBoxLibrary, initializeGLMap } from './mapBoxClientUtil';
import { deviceDetector } from '../utils/helper';
import ExpandIcon from './expandIcon';
import { noop, uniqueId } from 'lodash';
import FullScreenOverlay from './FullScreenOverlay';

class MapboxClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: deviceDetector.isMobile(),
      isViewPortRender:
        props.viewDevice === 'MOBILE_RENDER_DEVICE' && !!document.querySelector('.device-chrome'),
      showMobileOverlay: false
    };
    this.glMap = null;
    this.mapId = uniqueId('MapBox-');
  }

  componentDidMount() {
    loadMapBoxLibrary(this.generateMap);
  }

  componentWillUnmount() {
    this.glMap?.remove();
  }

  componentDidUpdate(prevProps) {
    if (!this.glMap) return void loadMapBoxLibrary(this.generateMap);

    const { lat, lon } = this.props;
    if (lat && lon && (lat !== prevProps.lat || lon !== prevProps.lon)) {
      updateSpot({
        map: this.glMap,
        lat,
        lon
      });
    }
  }

  toggleMobileOverlay = () => {
    this.setState({
      showMobileOverlay: !this.state.showMobileOverlay
    });
  };

  generateMap = () => {
    const { lat, lon, zoom, viewDevice, mapboxAccessToken, mapboxStyleUrl, renderMode } =
      this.props;
    const isMobileDevice =
      (viewDevice && viewDevice.match(/mobile/i)) ||
      (renderMode === coreConstants.renderModes.PUBLISH && deviceDetector.isMobile());

    if (!lat || !lon) return;
    this.glMap = initializeGLMap({
      containerId: this.mapId,
      interactive: !this.state.isRouteMobilePreviewPublish && !isMobileDevice,
      scrollZoom: false,
      lat,
      lon,
      zoom,
      accessToken: mapboxAccessToken,
      styleUrl: mapboxStyleUrl
    });
  };

  renderFullScreenMap = container => {
    const { lat, lon, zoom, mapboxAccessToken, mapboxStyleUrl } = this.props;
    initializeGLMap({
      container,
      interactive: true,
      lat,
      lon,
      zoom,
      scrollZoom: true,
      accessToken: mapboxAccessToken,
      styleUrl: mapboxStyleUrl
    });
  };

  handleGetDirections = () => {
    const { address } = this.props;
    openDeviceMap(address);
  };

  render() {
    const {
      lat,
      lon,
      zoom,
      address,
      staticContent,
      isPublishMode,
      isEditMode,
      viewDevice,
      overrideOverlay,
      renderMode
    } = this.props;
    if (!(Number(lat) && Number(lon) && Number(zoom))) return null;

    const { showMobileOverlay, isViewPortRender, isMobile } = this.state;
    const styles = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      cursor: 'pointer',
      display: 'block',
      transform: 'translate3d(0,0,0)'
    };

    const shouldRenderOverlay =
      overrideOverlay ||
      (isMobile && !isEditMode && viewDevice !== 'DESKTOP_RENDER_DEVICE') ||
      (isPublishMode && isMobile);
    const openFullScreenOverlay =
      shouldRenderOverlay && !isViewPortRender ? this.toggleMobileOverlay : noop;

    const expandIconProps = {
      address,
      staticContent,
      isRouteMobilePreviewPublish: shouldRenderOverlay,
      openFullScreenOverlay
    };

    return (
      <React.Fragment>
        <div
          id={ this.mapId }
          style={ styles }
          data-aid={ DataAid.CONTACT_MAP_REND }
          onClick={ openFullScreenOverlay }
        />
        { renderMode !== coreConstants.renderModes.PUBLISH && (
          <UX.Style shared>
            { '.mapboxgl-canvas { height: 100% !important; width: 100% !important; }' }
          </UX.Style>
        ) }
        <ExpandIcon { ...expandIconProps } />
        { showMobileOverlay && (
          <FullScreenOverlay
            getDirectionsLabel={ staticContent.mapCTA }
            renderMap={ this.renderFullScreenMap }
            handleClose={ this.toggleMobileOverlay }
            handleGetDirections={ this.handleGetDirections }
          />
        ) }
      </React.Fragment>
    );
  }
}

MapboxClient.propTypes = {
  lat: PropTypes.string,
  lon: PropTypes.string,
  zoom: PropTypes.number,
  viewDevice: PropTypes.string,
  address: PropTypes.string,
  env: PropTypes.string,
  mapboxAccessToken: PropTypes.string,
  mapboxStyleUrl: PropTypes.string,
  staticContent: PropTypes.object.isRequired,
  isPublishMode: PropTypes.bool,
  isEditMode: PropTypes.bool,
  overrideOverlay: PropTypes.bool,
  renderMode: PropTypes.string
};

MapboxClient.defaultProps = {
  staticContent: {
    mapCTA: ''
  }
};

export default MapboxClient;
