/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { UX2, constants as coreConstants } from '@wsb/guac-widget-core';
import DataAid from '../constants/data-aids';
import FullScreenOverlay from './FullScreenOverlay';
import { updateSpot, openDeviceMap } from './mapUtil';
import { loadGoogleMapsLibrary } from './googleMapsClientUtil';
import { getImageUrl, deviceDetector } from '../utils/helper';
import ExpandIcon from './expandIcon';
import StaticGoogleMap from './staticGoogleMap';
import StaticMap from './staticMap';
import { noop } from 'lodash';

class GoogleMapsClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: deviceDetector.isMobile(),
      isViewPortRender:
        props.viewDevice === 'MOBILE_RENDER_DEVICE' && !!document.querySelector('.device-chrome'),
      showMobileOverlay: false
    };
    this.generateGMap = this.generateGMap.bind(this);
    this.googleMap = null;
    this.mapContainer = null;
    this.currentCoords = { lastLat: null, lastLon: null };
  }

  componentDidMount() {
    loadGoogleMapsLibrary(
      !this.state.isMobile && this.generateGMap.bind(this, this.mapContainer, false)
    );
  }

  componentDidUpdate(prevProps) {
    const { lat, lon, address, isEditMode } = this.props;
    const { isMobile } = this.state;
    const { lastLat, lastLon } = this.currentCoords;
    if (lat && lon && lastLat !== lat && lastLon !== lon) {
      updateSpot({
        map: this.googleMap,
        lat,
        lon,
        isMapbox: false
      });
      this.currentCoords = { lastLat: lat, lastLon: lon };
    }
    if (prevProps.address !== address || (!isEditMode && !isMobile)) {
      this.generateGMap(this.mapContainer);
    }
  }

  toggleMobileOverlay = () => {
    this.setState({
      showMobileOverlay: !this.state.showMobileOverlay
    });
  };

  handleGetDirections = () => {
    const { address } = this.props;
    openDeviceMap(address);
  };

  renderFullScreenMap = container => {
    this.generateGMap(container, true);
  };

  generateGMap(targetContainer, isOverlay = false) {
    const { lat, lon, zoom, isEditMode, viewDevice, isPublishMode } = this.props;
    const { isMobile, isViewPortRender } = this.state;
    if (!lat || !lon || !window.google) {
      return;
    }

    const coords = {
      lat: parseFloat(lat, 10),
      lng: parseFloat(lon, 10)
    };

    const mapOptions = {
      center: coords,
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      scrollwheel: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl:
        (isOverlay || !isMobile || isEditMode || viewDevice === 'DESKTOP_RENDER_DEVICE') &&
        !isViewPortRender,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP
      }
    };

    if (isMobile && !isOverlay) {
      mapOptions.gestureHandling = 'none';
    }

    this.googleMap = new google.maps.Map(targetContainer, mapOptions);
    const marker = new google.maps.Marker({
      position: coords,
      map: this.googleMap
    });
  }

  render() {
    const {
      lat,
      lon,
      zoom,
      category,
      section,
      address,
      staticContent,
      renderMode,
      isPublishMode,
      viewDevice,
      isEditMode,
      overrideOverlay,
      env
    } = this.props;
    const { isMobile, isViewPortRender, showMobileOverlay } = this.state;

    const showMap = Boolean(Number(lat) && Number(lon) && Number(zoom));
    if (!showMap) return null;

    const styles = {
      shared: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        transform: 'translate3d(0,0,0)'
      },
      wrapper: {
        display: 'block',
        cursor: 'pointer',
        position: 'absolute !important' // Edit mode
      }
    };

    const shouldRenderOverlay =
      overrideOverlay || (isMobile && !isEditMode && viewDevice !== 'DESKTOP_RENDER_DEVICE');
    const openFullScreenOverlay =
      shouldRenderOverlay && !isViewPortRender ? this.toggleMobileOverlay : noop;
    const expandIconProps = {
      address,
      staticContent,
      isRouteMobilePreviewPublish: shouldRenderOverlay,
      openFullScreenOverlay
    };

    return (
      <UX2.Element.Block
        category={ category }
        section={ section }
        style={{ ...styles.shared, ...styles.wrapper }}
      >
        <div
          ref={ element => {
            this.mapContainer = element;
          } }
          style={ styles.shared }
          data-aid={ DataAid.CONTACT_MAP_REND }
          onClick={ openFullScreenOverlay }
        >
          { shouldRenderOverlay && <StaticGoogleMap { ...this.props } /> }
        </div>
        { !isViewPortRender && <ExpandIcon { ...expandIconProps } /> }
        { showMobileOverlay && (
          <FullScreenOverlay
            getDirectionsLabel={ staticContent.mapCTA }
            renderMap={ this.renderFullScreenMap }
            handleClose={ this.toggleMobileOverlay }
            handleGetDirections={ this.handleGetDirections }
          />
        ) }
      </UX2.Element.Block>
    );
  }
}

GoogleMapsClient.propTypes = {
  lat: PropTypes.string,
  lon: PropTypes.string,
  zoom: PropTypes.number,
  address: PropTypes.string,
  env: PropTypes.string,
  category: PropTypes.string,
  section: PropTypes.string,
  staticContent: PropTypes.object.isRequired,
  isPublishMode: PropTypes.bool,
  viewDevice: PropTypes.string,
  isEditMode: PropTypes.bool,
  overrideOverlay: PropTypes.bool,
  renderMode: PropTypes.string
};

GoogleMapsClient.defaultProps = {
  staticContent: {
    mapCTA: ''
  }
};

export default GoogleMapsClient;
