import local from './local';
import development from './development';
import test from './test';
import production from './production';

const baseConfig = {
  mapboxAccessToken:
    'pk.eyJ1IjoiZ29kYWRkeSIsImEiOiJjaWc5b20wcjcwczAydGFsdGxvamdvYnV0In0.JK9HuO6nAzc8BnMv6W7NBQ',
  mapboxStyleUrl: 'mapbox://styles/godaddy/ciovyeygh0029atm6zbntgxk2'
};

export default {
  local: Object.assign(local, baseConfig),
  development: Object.assign(development, baseConfig),
  test: Object.assign(test, baseConfig),
  production: Object.assign(production, baseConfig)
};
