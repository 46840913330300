import { deviceDetector } from '../utils/helper';

const getGeoJson = ({ lat, lon }) => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon, lat]
        }
      }
    ]
  };
};

const updateSpot = ({ map, lat, lon, isMapbox = true }) => {
  const doUpdateMapbox = () => {
    map.resize();
    map.easeTo({ center: [lon, lat] });
    map.getSource('markers').setData(getGeoJson({ lat, lon }));
  };
  const doUpdateGoogleMaps = () => {
    map.panTo({ lat: parseFloat(lat, 10), lng: parseFloat(lon, 10) });
  };
  const triggerUpdate = isMapbox ? doUpdateMapbox : doUpdateGoogleMaps;
  const didLoad = isMapbox ? map.loaded() : map && !!map.mapUrl;
  if (didLoad) {
    setTimeout(triggerUpdate, 200);
  } else if (isMapbox) {
    map.on('load', triggerUpdate);
  }
};

const openDeviceMap = address => {
  const url = deviceDetector.isIOS()
    ? `http://maps.apple.com/?q=${address}`
    : `http://www.google.com/maps/dir//${address}`;
  window.location.href = url;
};

export { getGeoJson, updateSpot, openDeviceMap };
